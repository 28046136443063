<template>
  <div class="upload-idx">
    <van-cell-group v-if="show_asset">
      <template #title>
        <div style="text-align: left;">请上传以下电子副本</div>
      </template>
      <van-field
        v-model="asset_photo"
        label="个人资产证明"
        input-align="right"
        placeholder="未添加"
        readonly
        right-icon="arrow"
        @click="link('upload_assets')"
      />
    </van-cell-group>
    <div v-else>
      <van-cell-group>
        <template #title>
          <div style="text-align: left;">您的材料已齐备，请直接提交！</div>
        </template>
      </van-cell-group>
    </div>
    <div style="width: 100%;text-align: center;">
      <van-button
        type="primary"
        class="btn"
        @click="next"
        :disabled="asset_photo !== '已添加' && show_asset"
      >
        提交
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button, CellGroup, Cell, Field,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted, ref,
} from 'vue';
import httpAddApi from '../../utils/httpAddApi';

export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
  },
  data() {
    return {};
  },
  methods: {
    link(name) {
      this.$router.push({ name });
    },
    async next() {
      const res = httpAddApi.submitInfo();
      await this.$api.submitLog({ type: 'submitAddOpenAccountInfo' });
      if (res && res.code === 200) this.$toast('提交成功');
      this.$router.push({ path: '/search/addAccount' });
    },
    before() {
      this.$router.go(-1);
    },
  },
  setup() {
    const asset_photo = ref(null);
    const show_asset = ref(false);
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '上传资料');
      store.commit('setStepsValue', ['风险认知', '提交资料(2/2)']);
      store.commit('setStepsActive', 1);
      const result = await httpAddApi.getImageResourcesStatus();
      if (!result) return;
      const result2 = await httpAddApi.getOpenAccountInfo();
      if (!result2) return;
      if (result2.add_major_invester !== result2.major_invester && result2.major_invester !== '是') {
        show_asset.value = true;
        asset_photo.value = result.data.asset_photo === 1 ? '已添加' : '';
      }
    });
    return {
      show_asset,
      asset_photo,
    };
  },
};
</script>

<style lang="less">
  .upload-idx {
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
